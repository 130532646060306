/* eslint-disable max-len */

import React from "react";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import style from "./style.module.scss";
import Stack from "@mui/material/Stack";
import Button from "@local/components/button";
import { ROLES } from "@local/legacy-utils/constants";
import ShowHide from "@local/components/show-hide";
import { getPseaRatingOptions } from "@local/utils/constants";
import DownloadIcon from "@local/components/download-icon";
import { getUrlFromFileField } from "@local/utils";
import JustificationSection from "../../../justification-section";
import Tooltip from "@mui/material/Tooltip";
import { getRatingScoreLabel } from "../../../../../../../../utils/adapters";
import { formatDate, isAgencyInitialSectionComplete } from "@local/pages/partner-details/components/psea/utils";
import InfoIcon from "@mui/icons-material/Info";
import i18n from "@local/i18next/i18n";

const getRating = (pseaSelfAssessment, isLowRisk, initialAssessment) => {
  let option = {};
  let ratingOptions = getPseaRatingOptions();
  let ratingScore = pseaSelfAssessment?.total_points_un?.total;
  let ratingLabel = ratingScore !== null ? getRatingScoreLabel(ratingScore, ratingOptions, undefined) : undefined;
  if (pseaSelfAssessment?.is_finalized_by_agency) option = ratingOptions?.find((item) => item.value === pseaSelfAssessment?.total_points_un?.un_score_and_rating);
  else option = getPseaRatingOptions().find((item) => item.value === initialAssessment?.agency_rating);
  if (option?.value === "not_eligible") {
    return <span className={style.notEligible}>{option?.extendedLabel}</span>;
  }
  if (isLowRisk && !initialAssessment?.agency_beneficiaries && !(!initialAssessment?.proceed_with_self_assessment && pseaSelfAssessment?.is_finalized_by_partner)) {
    const lowRiskRating = getPseaRatingOptions().find((item) => item.value === "low_risk");
    return (
      <span className={style.totalValue}>
        {lowRiskRating.extendedLabel} {ratingLabel ? `(${ratingLabel})` : ""}
      </span>
    );
  }
  return <span className={style.totalValue}>{option?.extendedLabel}</span>;
};

const Actions = ({
  onClickSaveForLater,
  onClickSubmit,
  onClickUnlock,
  onClickUnlockSelfAssessment,
  onClickUnlockRating,
  role,
  pseaSelfAssessment,
  partnerErrorMessages,
  agencyErrorMessages,
  hasViewPseaSelfAssessmentRatingPermission,
  hasUnlockPseaSelfAssessmentPartnerSectionPermission,
  hasSubmitPseaSelfAssessmentPermission,
  hasUnlockPseaSelfAssessmentAgencySectionPermission,
  hasEditPseaSelfAssessmentPermission,
  hasSpecialPseaPermission,
  isPartnerLocked,
  isAgencyLocked,
  justificationTypes,
  isLowRisk,
  initialAssessment,
  isDocumentRequired,
  selfAssessmentRevisions,
  partnerId,
  hasUnlockWarning,
  isAddJustificationAvailable,
}) => {
  const lastRevision = selfAssessmentRevisions?.[selfAssessmentRevisions.length - 1] ?? undefined;
  return (
    <ShowHide isVisible={pseaSelfAssessment}>
      <ShowHide isVisible={hasViewPseaSelfAssessmentRatingPermission}>
        <div className={style.resultsHeaderWrapper}>
          <div className={style.resultsHeader}>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.points",
            })}
          </div>
          <div className={style.resultsHeader}>
            {pseaSelfAssessment?.total_points_partner ? (
              <span className={style.totalValue}>{`${pseaSelfAssessment?.total_points_partner?.total} (${pseaSelfAssessment?.total_points_partner?.yes} ${formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.answerOptionYes",
              })}, ${pseaSelfAssessment?.total_points_partner?.no} ${formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.answerOptionNo",
              })}, ${pseaSelfAssessment?.total_points_partner?.na} ${formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.answerOptionNa",
              })})`}</span>
            ) : (
              <span className={style.totalEmptyLabel}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.yet",
                })}
              </span>
            )}
          </div>
          <div className={style.resultsHeader}>
            {pseaSelfAssessment?.is_finalized_by_agency ? (
              <span className={style.totalValue}>{`${pseaSelfAssessment?.total_points_un?.total} (${pseaSelfAssessment?.total_points_un?.yes} ${formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.answerOptionYes",
              })}, ${pseaSelfAssessment?.total_points_un?.no} ${formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.answerOptionNo",
              })}, ${pseaSelfAssessment?.total_points_un?.na} ${formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.answerOptionNa",
              })})`}</span>
            ) : (
              <span className={style.totalEmptyLabel}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.yet",
                })}
              </span>
            )}
          </div>
        </div>
        <div className={style.resultsHeaderWrapper}>
          <div className={style.resultsHeader}>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.rating",
            })}
          </div>
          <div className={style.resultsHeader} />
          <div className={style.resultsHeader}>
            {pseaSelfAssessment?.is_finalized_by_agency ? (
              <>{getRating(pseaSelfAssessment, isLowRisk, initialAssessment)}</>
            ) : (
              <span className={style.totalEmptyLabel}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.yet",
                })}
              </span>
            )}
          </div>
        </div>
        {role === ROLES.PARTNER ? (
          <span className={style.footerMessage}>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.footerMessage",
            })}
          </span>
        ) : (
          <></>
        )}
      </ShowHide>
      {pseaSelfAssessment?.snapshots?.length ? (
        <div className={style.contentWrapper}>
          <div className={style.snapshotInfoWrapper}>
            <span className={style.snapshotsLabel}>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.snapshotsLabel",
              })}
            </span>
            <div className={style.tooltipWrapper}>
              <Tooltip title={i18n.t("The snapshot is a downloadable PDF version of the scored self-assessment. A new snapshot will be available every time the section is updated")}>
                <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
              </Tooltip>
            </div>
          </div>
          <div className={style.snapshotsWrapper}>
            {pseaSelfAssessment?.snapshots.map((item) => (
              <div key={`psea_self_assessment_snapshot_item_${item.id}`}>
                <DownloadIcon fileUrl={getUrlFromFileField(item.file.file_field)} fileName={formatDate(item.created)} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
      {pseaSelfAssessment?.unlock_assessment_justification ? (
        <>
          <div className={style.justificationWrapper}>
            <span className={style.justificationLabel}>
              {role === ROLES.PARTNER
                ? formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.justificationInfoPartner",
                  })
                : formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.justificationInfoAgency",
                  })}
            </span>
            <span className={style.justificationValue}>
              {pseaSelfAssessment?.unlock_assessment_justification?.endsWith("_other") ? (
                <span>{pseaSelfAssessment?.unlock_assessment_comment}</span>
              ) : (
                <>
                  <span>{justificationTypes?.find((item) => item.justification === pseaSelfAssessment?.unlock_assessment_justification)?.description}</span>
                  <span>{pseaSelfAssessment?.unlock_assessment_comment !== null ? ` - ${pseaSelfAssessment?.unlock_assessment_comment}` : ""}</span>
                </>
              )}
            </span>
          </div>
          <span className={style.dateText}>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.activitiesCompletedBy",
            })}{" "}
            {pseaSelfAssessment?.unlock_assessment_by_agency} on {formatDate(pseaSelfAssessment?.unlock_assessment_date)}
          </span>
        </>
      ) : (
        <></>
      )}
      {pseaSelfAssessment?.unlock_rating_justification ? (
        <>
          <div className={style.justificationWrapper}>
            <span className={style.justificationLabel}>
              {role === ROLES.PARTNER
                ? formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.ratingJustificationInfoPartner",
                  })
                : formatMessage({
                    id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.ratingJustificationInfoAgency",
                  })}
            </span>
            <span className={style.justificationValue}>
              {pseaSelfAssessment?.unlock_rating_justification?.endsWith("_other") ? (
                <span>{pseaSelfAssessment?.unlock_rating_comment}</span>
              ) : (
                <>
                  <span>{justificationTypes?.find((item) => item.justification === pseaSelfAssessment?.unlock_rating_justification)?.description}</span>
                  <span>{pseaSelfAssessment?.unlock_rating_comment !== null ? ` - ${pseaSelfAssessment?.unlock_rating_comment}` : ""}</span>
                </>
              )}
            </span>
          </div>
          <span className={style.dateText}>
            {formatMessage({
              id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.activitiesCompletedBy",
            })}{" "}
            {pseaSelfAssessment?.unlock_rating_by_agency} on {formatDate(pseaSelfAssessment?.unlock_rating_date)}
          </span>
        </>
      ) : (
        <></>
      )}
      {selfAssessmentRevisions?.find((item) => item.justification_to_proceed) && role === ROLES.AGENCY ? (
        <span className={style.justificationsToProceedTitle}>
          {formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.justificationTitle",
          })}
        </span>
      ) : (
        <></>
      )}
      {selfAssessmentRevisions
        ?.filter((item) => item.justification_to_proceed && item.id !== lastRevision.id)
        .map((item) => (
          <JustificationSection key={`justification_to_proceed_${item.id}`} revisionToJustify={item} partnerId={partnerId} isAddJustificationAvailable={false} />
        ))}
      {lastRevision ? <JustificationSection revisionToJustify={lastRevision} partnerId={partnerId} isAddJustificationAvailable={isAddJustificationAvailable} /> : <></>}
      {role === ROLES.AGENCY && hasUnlockWarning ? (
        <div className={style.unlockWarningMessageWrapper}>
          {formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.selfAssessmentAgencyUnlockWarning",
          })}
        </div>
      ) : (
        <>
          <div className={style.actionButtons}>
            <Stack direction="row" spacing={2}>
              <div>
                <div className={style.actionButtonsWrapper}>
                  <ShowHide isVisible={(role === ROLES.PARTNER && !isPartnerLocked) || (role === ROLES.AGENCY && !isAgencyLocked)}>
                    <Button
                      isDisabled={!hasEditPseaSelfAssessmentPermission}
                      onClick={onClickSaveForLater}
                      text={formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.later",
                      })}
                      variant="text"
                    />
                  </ShowHide>
                  <ShowHide isVisible={(role === ROLES.PARTNER && !isPartnerLocked) || (role === ROLES.AGENCY && !isAgencyLocked)}>
                    <Button
                      isDisabled={(role === ROLES.PARTNER && partnerErrorMessages?.length) || (role === ROLES.AGENCY && agencyErrorMessages?.length) || !hasSubmitPseaSelfAssessmentPermission || isDocumentRequired}
                      onClick={onClickSubmit}
                      text={formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.text",
                      })}
                    />
                  </ShowHide>
                </div>
                <div className={style.actionButtonsWrapper}>
                  <ShowHide isVisible={role === ROLES.PARTNER && pseaSelfAssessment?.is_finalized_by_partner === true}>
                    <Tooltip
                      title={formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.unlockInfo",
                      })}
                    >
                      <span>
                        <Button
                          isDisabled={
                            pseaSelfAssessment?.is_finalized_by_agency === true ||
                            !hasUnlockPseaSelfAssessmentPartnerSectionPermission ||
                            (pseaSelfAssessment?.is_finalized_by_partner === true && pseaSelfAssessment?.questions?.find((item) => item?.agency_answer) && pseaSelfAssessment?.is_finalized_by_agency === false)
                          }
                          onClick={onClickUnlock}
                          text={formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.unlock",
                          })}
                        />
                      </span>
                    </Tooltip>
                  </ShowHide>
                  <ShowHide isVisible={role === ROLES.AGENCY && isPartnerLocked}>
                    <Tooltip
                      title={formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.assessmentInfo",
                      })}
                    >
                      <span>
                        <Button
                          isDisabled={!hasUnlockPseaSelfAssessmentPartnerSectionPermission || !isAgencyInitialSectionComplete(role, initialAssessment)}
                          onClick={onClickUnlockSelfAssessment}
                          text={formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.assessment",
                          })}
                        />
                      </span>
                    </Tooltip>
                  </ShowHide>
                  <ShowHide isVisible={role === ROLES.AGENCY && pseaSelfAssessment?.is_finalized_by_agency === true}>
                    <Tooltip
                      title={formatMessage({
                        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.ratingInfo",
                      })}
                    >
                      <span>
                        <Button
                          isDisabled={
                            ((pseaSelfAssessment?.is_finalized_by_partner ?? false) === false && !hasSpecialPseaPermission) ||
                            !hasUnlockPseaSelfAssessmentAgencySectionPermission ||
                            !(pseaSelfAssessment?.is_finalized_by_agency === true && hasSpecialPseaPermission) ||
                            !isAgencyInitialSectionComplete(role, initialAssessment)
                          }
                          onClick={onClickUnlockRating}
                          text={formatMessage({
                            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.ratingOne",
                          })}
                        />
                      </span>
                    </Tooltip>
                  </ShowHide>
                </div>
              </div>
            </Stack>
          </div>
          <ShowHide isVisible={isDocumentRequired}>
            <div className={style.actionButton}>
              <small className={style.actionsWaring}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.cmp.actions.idx.selfAssessmentDocumentsWarningMessage",
                })}
              </small>
            </div>
          </ShowHide>
        </>
      )}
    </ShowHide>
  );
};

Actions.propTypes = {};

export default Actions;
