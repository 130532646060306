import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import style from "./style.module.scss";
import i18n from "@local/i18next/i18n";
const MethodologyLink = ({ show }) => {
  return (
    <div>
      {show ? (
        <div style={{ marginBottom: "40px" }} className={style.linkItemWrapper}>
          <a target="_blank" alt={"psea assessment checklist"} href="https://supportagency.unpartnerportal.org/hc/en-us/articles/14876971142167-Interagency-PSEA-IP-Protocol-Resource-Package-for-UN-Personnel" rel="noreferrer">
            {i18n.t("PSEA assessment checklist")}
          </a>
          <OpenInNewIcon style={{ fontSize: ".85rem", color: "#777777" }} />
        </div>
      ) : null}
    </div>
  );
};

export default MethodologyLink;
