import React from "react";
import style from "./style.module.scss";
import Chip from "@mui/material/Chip";
import Button from "@local/components/button";
import DownloadIcon from "@local/components/download-icon";
import { ROLES } from "@local/legacy-utils/constants";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import { getUrlFromFileField } from "@local/utils";
import JustificationSection from "../../../justification-section";
import { formatDate } from "@local/pages/partner-details/components/psea/utils";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import i18n from "@local/i18next/i18n";
const getSixMonthsJustificationSection = (revisionToJustifySixMonths, revisionToJustifyNineMonths, partnerId) => {
  if (revisionToJustifyNineMonths) {
    if (revisionToJustifySixMonths?.justification_to_proceed) {
      return <JustificationSection revisionToJustify={revisionToJustifySixMonths} partnerId={partnerId} isAddJustificationAvailable />;
    }
  } else if (revisionToJustifySixMonths) {
    return <JustificationSection revisionToJustify={revisionToJustifySixMonths} partnerId={partnerId} isAddJustificationAvailable />;
  } else {
    return <></>;
  }
};

const getNineMonthsJustificationSection = (revisionToJustifyNineMonths, partnerId) => {
  if (revisionToJustifyNineMonths) {
    return <JustificationSection revisionToJustify={revisionToJustifyNineMonths} partnerId={partnerId} isAddJustificationAvailable />;
  } else {
    return <></>;
  }
};

const Actions = ({ role, onClickCompletePseaCsip, pseaCsipDetails, hasCompletePseaCsipPermission, revisionToJustifySixMonths, revisionToJustifyNineMonths, partnerId }) => {
  const canBeSubmitted = pseaCsipDetails?.core_standards?.find((cs) => cs.rating === "no" && !cs.is_optional && !cs.activities.find((activity) => activity.is_required && activity.status !== "inactivated")) === undefined;
  return (
    <>
      <div className={style.snapshotInfoWrapper}>
        <span className={style.snapshotsLabel}>
          {formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.actions.idx.snapshots",
          })}
        </span>
        <div className={style.tooltipWrapper}>
          <Tooltip title={i18n.t("The snapshot is a downloadable PDF version of the scored self-assessment. A new snapshot will be available every time the section is updated")}>
            <InfoIcon style={{ fontSize: ".8rem", color: "#555555" }} />
          </Tooltip>
        </div>
      </div>
      <div className={style.snapshotsWrapper}>
        {pseaCsipDetails?.snapshots?.length ? (
          <>
            {pseaCsipDetails?.snapshots.map((item) => (
              <div key={`psea_csip_snapshot_item_${item.id}`}>
                <DownloadIcon fileUrl={getUrlFromFileField(item.file.file_field)} fileName={formatDate(item.created)} />
              </div>
            ))}
          </>
        ) : (
          <>
            <span>
              {formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.actions.idx.snapshotsLabelEmpty",
              })}
            </span>
          </>
        )}
      </div>
      {(revisionToJustifySixMonths || revisionToJustifyNineMonths) && role === ROLES.AGENCY ? (
        <span className={style.justificationsToProceedTitle}>
          {formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.actions.idx.justificationTitle",
          })}
        </span>
      ) : (
        <></>
      )}
      {getSixMonthsJustificationSection(revisionToJustifySixMonths, revisionToJustifyNineMonths, partnerId)}
      {getNineMonthsJustificationSection(revisionToJustifyNineMonths, partnerId)}
      <div className={style.footerWrapper}>
        {pseaCsipDetails?.completed_by ? (
          <>
            <div>
              <span className={style.footerTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.actions.idx.completedBy",
                })}
              </span>
              <div className={style.footerDescription}>
                <Chip label={pseaCsipDetails?.agency_name} size="small" />
              </div>
            </div>
            <div>
              <span className={style.footerTitle}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.actions.idx.completedOn",
                })}
              </span>
              <span className={style.footerDescription}>{formatDate(pseaCsipDetails?.date_of_completion)}</span>
            </div>
          </>
        ) : (
          <></>
        )}
        {role === ROLES.AGENCY ? (
          <div className={style.actionButtons}>
            <Button
              isDisabled={!hasCompletePseaCsipPermission || !canBeSubmitted || !pseaCsipDetails?.is_draft}
              onClick={onClickCompletePseaCsip}
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.actions.idx.updateCsip",
              })}
            />
            {!canBeSubmitted ? (
              <small className={style.actionsWaring}>
                {formatMessage({
                  id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.actions.idx.actionsWaring",
                })}
              </small>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

Actions.propTypes = {};

export default Actions;
