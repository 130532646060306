import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import style from "./style.module.scss";
import i18n from "@local/i18next/i18n";
const CsipLink = ({ isAgency }) => {
  return (
    <div>
      <div style={{ marginBottom: "40px" }} className={style.linkItemWrapper}>
        <a
          target="_blank"
          alt={"psea assessment checklist"}
          href={
            isAgency
              ? "https://supportagency.unpartnerportal.org/hc/en-us/sections/12381676568087-Resources-to-support-PSEA-capacity-strengthening"
              : "https://supportcso.unpartnerportal.org/hc/en-us/sections/12968272283031-Resources-to-support-PSEA-capacity-strengthening"
          }
          rel="noreferrer"
        >
          {i18n.t("Capacity strengthening resources")}
        </a>
        <OpenInNewIcon style={{ fontSize: ".85rem", color: "#777777" }} />
      </div>
    </div>
  );
};

export default CsipLink;
