/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { reset, isValid, reduxForm } from "redux-form";
import SectionContainer from "../section-container";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import UploadDocumentModal from "./components/upload-document-modal";
import Questions from "./components/questions";
import Documents from "./components/documents";
import Actions from "./components/actions";
import { getPseaCoreStandards } from "@local/utils/constants";
import {
  getPseaSelfAssessmentDetails,
  updatePseaSelfAssessmentQuestion,
  updatePseaSelfAssessment,
  getPseaSelfAssessmentDocumentsList,
  createPseaSelfAssessmentDocument,
  deletePseaSelfAssessmentDocument,
  getPseaDetails,
  getPseaSelfAssessmentJustificationsList,
  getPseaCsipDetails,
  getPseaInitialAssessmentDetails,
} from "@local/actions";
import { adaptSelfAssessmentFormValuesToBeSent, adaptSelfAssessmentFormValuesInitialValues } from "../../../../utils/adapters";
import { validatePartnerSelfAssessmentQuestions, validateAgencySelfAssessmentQuestions } from "../../../../utils/validators";
import { checkPermission, AGENCY_PERMISSIONS, PARTNER_PERMISSIONS } from "@local/legacy-utils/permissions";
import { ROLES } from "@local/legacy-utils/constants";
import { isPartnerLocked, isAgencyLocked } from "../../../../utils";
import UnlockModal from "./components/unlock-modal";
import MethodologyLink from "./components/methodology-checklist";

const getIsDocumentRequired = (selfAssessmentFormValues, pseaSelfAssessmentDocuments, isPartnerLockedValue, isPartnerLocked, role) => {
  if (
    (selfAssessmentFormValues[`${role}_CS1`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS2`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS3`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS4`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS5`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS6`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS7`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS8`] === "yes" ||
      selfAssessmentFormValues[`${role}_CS8`] === "no") &&
    pseaSelfAssessmentDocuments?.length === 0 &&
    ((role === ROLES.PARTNER && !isPartnerLockedValue) || (role === ROLES.AGENCY && isPartnerLocked))
  ) {
    return true;
  }
  return false;
};

const SelfAssessment = ({
  documentTypes,
  role,
  pseaSelfAssessment,
  pseaSelfAssessmentDocuments,
  selfAssessmentFormValues,
  selfAssessmentDocumentFormValues,
  isSelfAssessmentDocumentFormValid,
  resetSelfAssessmentDocumentForm,
  getPseaSelfAssessmentDetails,
  updatePseaSelfAssessmentQuestion,
  updatePseaSelfAssessment,
  getPseaSelfAssessmentDocumentsList,
  createPseaSelfAssessmentDocument,
  deletePseaSelfAssessmentDocument,
  pseaSelfAssessmentInitialFormValues,
  hasEditPseaSelfAssessmentPermission,
  hasViewPseaSelfAssessmentRatingPermission,
  hasEditPseaSelfAssessmentDocumentsPermission,
  hasUnlockPseaSelfAssessmentPartnerSectionPermission,
  hasSubmitPseaSelfAssessmentPermission,
  hasUnlockPseaSelfAssessmentAgencySectionPermission,
  getPseaDetails,
  psea,
  hasSpecialPseaPermission,
  canAgencySkipPartner,
  pseaSelfAssessmentJustifications,
  selfAssessmentUnlockFormValues,
  isSelfAssessmentUnlockFormValid,
  resetSelfAssessmentUnlockForm,
  pseaInitialAssessment,
  partnerId,
  getPseaCsipDetails,
  selfAssessmentRevisions,
  getPseaInitialAssessmentDetails,
  isAddJustificationAvailable,
}) => {
  const [isSelfAssessmentDocumentModalVisible, setIsSelfAssessmentDocumentModalVisible] = useState(false);
  const [unlockPayload, setUnlockPayload] = useState(undefined);
  const [isCsipWarningConfirmed, setIsCsipWarningConfirmed] = useState(false);
  const [warningMessages, setWarningMessages] = useState([]);

  useEffect(() => {
    setWarningMessages(
      pseaSelfAssessment && pseaSelfAssessment?.is_finalized_by_partner && role === ROLES.AGENCY
        ? [
            {
              title: formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.selfAssessmentWarrningTitle",
              }),
              tooltip: formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.selfAssessmentWarrningTooltip",
              }),
            },
          ]
        : undefined,
    );
  }, [pseaSelfAssessment?.is_finalized_by_partner]);

  const onChangeCsipWarningConfirmation = (value) => setIsCsipWarningConfirmed(value);

  const onClickUploadSelfAssessmentDocument = () => {
    setIsSelfAssessmentDocumentModalVisible(true);
  };

  const onClickCancelUploadSelfAssessmentDocument = () => {
    setIsSelfAssessmentDocumentModalVisible(false);
    resetSelfAssessmentDocumentForm();
  };

  const onClickSubmitUploadSelfAssessmentDocument = async () => {
    try {
      await createPseaSelfAssessmentDocument({
        file: selfAssessmentDocumentFormValues?.file?.id,
        core_standards: selfAssessmentDocumentFormValues?.core_standards ?? undefined,
      });
      getPseaSelfAssessmentDocumentsList();
      setIsSelfAssessmentDocumentModalVisible(false);
      resetSelfAssessmentDocumentForm();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickDeleteDocument = async (item) => {
    try {
      await deletePseaSelfAssessmentDocument(item.id);
      getPseaSelfAssessmentDocumentsList();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickSaveForLater = async () => {
    try {
      await updatePseaSelfAssessmentQuestion(adaptSelfAssessmentFormValuesToBeSent(selfAssessmentFormValues, role));
      getPseaSelfAssessmentDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickSubmit = async () => {
    try {
      await updatePseaSelfAssessmentQuestion(adaptSelfAssessmentFormValuesToBeSent(selfAssessmentFormValues, role));
      await updatePseaSelfAssessment(role === ROLES.AGENCY ? { is_finalized_by_agency: true } : { is_finalized_by_partner: true });
      getPseaSelfAssessmentDetails();
      getPseaDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickUnlock = async () => {
    try {
      await updatePseaSelfAssessment({ is_finalized_by_partner: false });
      getPseaSelfAssessmentDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onClickUnlockSelfAssessment = async () => {
    if (role === ROLES.AGENCY) {
      if (!pseaSelfAssessment?.force_self_assessment) {
        await updatePseaSelfAssessment({ force_self_assessment: true });
      }
      setUnlockPayload({ is_finalized_by_partner: false });
    } else {
      try {
        await updatePseaSelfAssessment({ is_finalized_by_partner: false });
        getPseaSelfAssessmentDetails();
      } catch (error) {
        toast.error(
          formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
          }),
        );
      }
    }
  };

  const onClickUnlockRating = async () => {
    if (role === ROLES.AGENCY) {
      setUnlockPayload({ is_finalized_by_agency: false });
    } else {
      try {
        await updatePseaSelfAssessment({ is_finalized_by_agency: false });
        getPseaSelfAssessmentDetails();
      } catch (error) {
        toast.error(
          formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
          }),
        );
      }
    }
  };

  const onSubmitUnlock = async () => {
    try {
      await updatePseaSelfAssessment({
        ...unlockPayload,
        ...selfAssessmentUnlockFormValues,
      });
      getPseaSelfAssessmentDetails();
      resetSelfAssessmentUnlockForm();
      setIsCsipWarningConfirmed(false);
      setUnlockPayload(undefined);
      getPseaCsipDetails();
      getPseaInitialAssessmentDetails();
    } catch (error) {
      toast.error(
        formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.unableToSaveData",
        }),
      );
    }
  };

  const onCancelUnlock = () => {
    resetSelfAssessmentUnlockForm();
    setIsCsipWarningConfirmed(false);
    setUnlockPayload(undefined);
  };

  const isSelfAssessmentUnlocked = pseaSelfAssessment?.unlock_assessment_justification && pseaSelfAssessment?.is_finalized_by_partner === false;
  const isPartnerLockedValue = pseaSelfAssessment?.agency_skipped_partner || isPartnerLocked(pseaSelfAssessment, hasEditPseaSelfAssessmentPermission, pseaInitialAssessment);
  const isAgencyLockedValue = isSelfAssessmentUnlocked || isAgencyLocked(role, pseaSelfAssessment, hasEditPseaSelfAssessmentPermission, canAgencySkipPartner, hasUnlockPseaSelfAssessmentAgencySectionPermission, pseaInitialAssessment);
  const isLowRisk = psea?.revisions?.find((item) => item.revision_type === "initial_assessment" && item.revision_rating === "low_risk") !== undefined ?? false;
  const isDocumentRequired = getIsDocumentRequired(selfAssessmentFormValues, pseaSelfAssessmentDocuments, isPartnerLockedValue, isPartnerLocked, role);
  const partnerErrorMessages = validatePartnerSelfAssessmentQuestions(selfAssessmentFormValues, pseaSelfAssessment, isPartnerLockedValue);
  const agencyErrorMessages = validateAgencySelfAssessmentQuestions(selfAssessmentFormValues, isAgencyLockedValue);

  return (
    <>
      <SectionContainer
        title={formatMessage({
          id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.preliminaryAssessment",
        })}
        hasWarningIcon={role === ROLES.PARTNER && isSelfAssessmentUnlocked}
        warningMessages={warningMessages}
      >
        <MethodologyLink show={role === ROLES.AGENCY} />

        <Questions
          role={role}
          initialValues={pseaSelfAssessmentInitialFormValues}
          partnerErrorMessages={partnerErrorMessages}
          agencyErrorMessages={agencyErrorMessages}
          pseaSelfAssessment={pseaSelfAssessment}
          isPartnerLocked={isPartnerLockedValue}
          isAgencyLocked={isAgencyLockedValue}
          pseaInitialAssessment={pseaInitialAssessment}
        />
        <Documents
          onClickAdd={onClickUploadSelfAssessmentDocument}
          documents={pseaSelfAssessmentDocuments}
          onClickDeleteDocument={onClickDeleteDocument}
          role={role}
          documentTypes={documentTypes}
          hasEditPseaSelfAssessmentDocumentsPermission={hasEditPseaSelfAssessmentDocumentsPermission}
          hasSpecialPseaPermission={hasSpecialPseaPermission}
          isPartnerLocked={isPartnerLockedValue}
          isAgencyLocked={isAgencyLockedValue}
          isDocumentRequired={isDocumentRequired}
        />
        <Actions
          onClickSaveForLater={onClickSaveForLater}
          onClickSubmit={onClickSubmit}
          onClickUnlock={onClickUnlock}
          onClickUnlockSelfAssessment={onClickUnlockSelfAssessment}
          onClickUnlockRating={onClickUnlockRating}
          role={role}
          pseaSelfAssessment={pseaSelfAssessment}
          partnerErrorMessages={partnerErrorMessages}
          agencyErrorMessages={agencyErrorMessages}
          hasViewPseaSelfAssessmentRatingPermission={hasViewPseaSelfAssessmentRatingPermission}
          hasUnlockPseaSelfAssessmentPartnerSectionPermission={hasUnlockPseaSelfAssessmentPartnerSectionPermission}
          hasSubmitPseaSelfAssessmentPermission={hasSubmitPseaSelfAssessmentPermission}
          hasUnlockPseaSelfAssessmentAgencySectionPermission={hasUnlockPseaSelfAssessmentAgencySectionPermission}
          hasEditPseaSelfAssessmentPermission={hasEditPseaSelfAssessmentPermission}
          hasSpecialPseaPermission={hasSpecialPseaPermission}
          isPartnerLocked={isPartnerLockedValue}
          isAgencyLocked={isAgencyLockedValue}
          justificationTypes={pseaSelfAssessmentJustifications}
          isLowRisk={isLowRisk}
          initialAssessment={pseaInitialAssessment}
          isDocumentRequired={isDocumentRequired}
          selfAssessmentRevisions={selfAssessmentRevisions}
          partnerId={partnerId}
          hasUnlockWarning={role === ROLES.AGENCY && isSelfAssessmentUnlocked}
          isAddJustificationAvailable={isAddJustificationAvailable}
        />
      </SectionContainer>
      <UploadDocumentModal onCancel={onClickCancelUploadSelfAssessmentDocument} onSubmit={onClickSubmitUploadSelfAssessmentDocument} isOpen={isSelfAssessmentDocumentModalVisible} documentTypes={documentTypes} isFormValid={isSelfAssessmentDocumentFormValid} />
      <UnlockModal
        isOpen={unlockPayload !== undefined}
        onSubmit={onSubmitUnlock}
        onCancel={onCancelUnlock}
        justificationTypes={pseaSelfAssessmentJustifications?.filter((item) => item.type === (unlockPayload?.is_finalized_by_partner === false ? "self_assessment" : "rating"))}
        title={
          unlockPayload?.is_finalized_by_partner === false
            ? formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.justificationTitle",
              })
            : formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.self.assessment.idx.justificationTitleOne",
              })
        }
        isFormValid={isSelfAssessmentUnlockFormValid}
        isCsipWarningConfirmed={isCsipWarningConfirmed}
        onChangeCsipWarningConfirmation={onChangeCsipWarningConfirmation}
        isCommentRequired={selfAssessmentUnlockFormValues?.unlock_assessment_justification?.endsWith("_other") || selfAssessmentUnlockFormValues?.unlock_rating_justification?.endsWith("_other")}
        unlockType={unlockPayload?.is_finalized_by_partner === false ? "self_assessment" : "rating"}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const selfAssessmentFormValues = state.form?.pseaSelfAssessmentForm?.values ?? {};
  const selfAssessmentDocumentFormValues = state.form?.pseaSelfAssessmentDocumentForm?.values ?? {};
  const selfAssessmentUnlockFormValues = state.form?.pseaSelfAssessmentUnlockForm?.values ?? {};
  const pseaSelfAssessment = state?.pseaSelfAssessmentDetails?.data?.data ?? undefined;
  const role = state?.session?.role;
  const hasSpecialPseaPermission = role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.SPECIAL_PSEA_PERMISSIONS, state) : checkPermission(PARTNER_PERMISSIONS.SPECIAL_PSEA_PERMISSIONS, state);

  const canAgencySkipPartner = (role === ROLES.AGENCY && hasSpecialPseaPermission) ?? false;

  return {
    documentTypes: getPseaCoreStandards(),
    role,
    pseaSelfAssessment,
    pseaSelfAssessmentDocuments: state?.pseaSelfAssessmentDocumentsList?.data?.data ?? [],
    selfAssessmentFormValues,
    selfAssessmentDocumentFormValues,
    isSelfAssessmentDocumentFormValid: isValid("pseaSelfAssessmentDocumentForm")(state),
    selfAssessmentUnlockFormValues,
    isSelfAssessmentUnlockFormValid: isValid("pseaSelfAssessmentUnlockForm")(state),
    pseaSelfAssessmentInitialFormValues: adaptSelfAssessmentFormValuesInitialValues(pseaSelfAssessment),
    psea: state.pseaDetails.data?.data ?? {},
    canAgencySkipPartner: canAgencySkipPartner,
    pseaSelfAssessmentJustifications: state?.pseaSelfAssessmentJustificationsList?.data?.data?.justifications ?? [],
    hasEditPseaSelfAssessmentPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.EDIT_PSEA_SELF_ASSESSMENT, state) : checkPermission(PARTNER_PERMISSIONS.EDIT_PSEA_SELF_ASSESSMENT, state),
    hasViewPseaSelfAssessmentRatingPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.VIEW_PSEA_SELF_ASSESSMENT_RATING, state) : checkPermission(PARTNER_PERMISSIONS.VIEW_PSEA_SELF_ASSESSMENT_RATING, state),
    hasEditPseaSelfAssessmentDocumentsPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.EDIT_PSEA_SELF_ASSESSMENT, state) : checkPermission(PARTNER_PERMISSIONS.EDIT_PSEA_SELF_ASSESSMENT_DOCUMENTS, state),
    hasUnlockPseaSelfAssessmentPartnerSectionPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.UNLOCK_PSEA_SELF_ASSESSMENT_PARTNER_SECTION, state) : checkPermission(PARTNER_PERMISSIONS.UNLOCK_PSEA_SELF_ASSESSMENT_PARTNER_SECTION, state),
    hasUnlockPseaSelfAssessmentAgencySectionPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.UNLOCK_PSEA_SELF_ASSESSMENT_AGENCY_SECTION, state) : false,
    hasSubmitPseaSelfAssessmentPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.COMPLETE_PSEA_SELF_ASSESSMENT, state) : checkPermission(PARTNER_PERMISSIONS.SUBMIT_PSEA_SELF_ASSESSMENT, state),
    hasSpecialPseaPermission: role === ROLES.AGENCY ? checkPermission(AGENCY_PERMISSIONS.SPECIAL_PSEA_PERMISSIONS, state) : checkPermission(PARTNER_PERMISSIONS.SPECIAL_PSEA_PERMISSIONS, state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  resetSelfAssessmentDocumentForm: () => dispatch(reset("pseaSelfAssessmentDocumentForm")),
  resetSelfAssessmentUnlockForm: () => dispatch(reset("pseaSelfAssessmentUnlockForm")),
  getPseaSelfAssessmentDetails: () =>
    dispatch(
      getPseaSelfAssessmentDetails(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
  updatePseaSelfAssessmentQuestion: (data) => dispatch(updatePseaSelfAssessmentQuestion(data, { partnerId: ownProps.partnerId })),
  updatePseaSelfAssessment: (data) => dispatch(updatePseaSelfAssessment(data, { partnerId: ownProps.partnerId })),
  getPseaSelfAssessmentDocumentsList: () =>
    dispatch(
      getPseaSelfAssessmentDocumentsList(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
  createPseaSelfAssessmentDocument: (data) => dispatch(createPseaSelfAssessmentDocument(data, { partnerId: ownProps.partnerId })),
  deletePseaSelfAssessmentDocument: (id) => dispatch(deletePseaSelfAssessmentDocument({ partnerId: ownProps.partnerId, id })),
  getPseaDetails: () => dispatch(getPseaDetails(undefined, { partnerId: ownProps.partnerId })),
  getPseaSelfAssessmentJustifications: () => dispatch(getPseaSelfAssessmentJustificationsList()),
  getPseaCsipDetails: () => dispatch(getPseaCsipDetails(undefined, { partnerId: ownProps.partnerId })),
  getPseaInitialAssessmentDetails: () =>
    dispatch(
      getPseaInitialAssessmentDetails(undefined, {
        partnerId: ownProps.partnerId,
      }),
    ),
});

export default reduxForm({
  form: "pseaSelfAssessmentForm",
  enableReinitialize: true,
})(connect(mapStateToProps, mapDispatchToProps)(SelfAssessment));
