import React from "react";
import Modal from "@local/components/modal";
import { formatMessage } from "@local/legacy-utils/i18nHelper";
import FieldGroup from "@local/components/field-group";
import Button from "@local/components/button";
import FieldFile from "@local/components/field-file";
import { reduxForm, Field } from "redux-form";
import { isRequired } from "@local/utils/validators";
import style from "./style.module.scss";
import i18n from "@local/i18next/i18n";
const UploadDocumentModal = ({ onCancel, onSubmit, isOpen, isFormValid }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={formatMessage({
        id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.upload.document.modal.idx.addDocumentTitle",
      })}
      footer={
        <>
          <div>
            <Button
              onClick={onCancel}
              variant="text"
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.upload.document.modal.idx.cancel",
              })}
            />
          </div>
          <div>
            <Button
              isDisabled={!isFormValid}
              onClick={onSubmit}
              text={formatMessage({
                id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.upload.document.modal.idx.button",
              })}
            />
          </div>
        </>
      }
    >
      <FieldGroup>
        <Field
          component={FieldFile}
          validate={[isRequired]}
          id="file"
          name="file"
          label={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.upload.document.modal.idx.uploadDocumentFieldFile",
          })}
          placeholder={formatMessage({
            id: "pages.partner.details.cmp.psea.cmp.mn.cmp.csip.cmp.upload.document.modal.idx.uploadDocumentFieldFile",
          })}
        />
      </FieldGroup>
      <div className={style.textBold}>
        <b>*{i18n.t("Do not upload confidential data and/or sensitive personal data with your supporting documentation (i.e., copy of IDs, identifying information related to allegations of SEA, etc.)")}</b>
      </div>
    </Modal>
  );
};

UploadDocumentModal.propTypes = {};

export default reduxForm({
  form: "pseaCsipCoreStandardDocumentForm",
  enableReinitialize: true,
})(UploadDocumentModal);
